import React from 'react';

function ListingBeds(props) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.35035 17.16H5.02035C4.65584 17.16 4.36035 16.8645 4.36035 16.5V14.41C4.36035 14.0455 4.65584 13.75 5.02035 13.75H17.4504C17.8149 13.75 18.1104 14.0455 18.1104 14.41V16.5C18.1104 16.8645 17.8149 17.16 17.4504 17.16H17.1204C16.7558 17.16 16.4604 16.8645 16.4604 16.5V15.84C16.4604 15.4755 16.1649 15.18 15.8004 15.18H6.67035C6.30584 15.18 6.01035 15.4755 6.01035 15.84V16.5C6.01035 16.8645 5.71486 17.16 5.35035 17.16Z"
        stroke="black"
        strokeWidth="0.66"
      />
      <path
        d="M4.36045 15.18H3.37045C3.00594 15.18 2.71045 14.8845 2.71045 14.52V12.43C2.71045 10.582 4.91045 9.57001 6.78045 9.57001H11.4004"
        stroke="black"
        strokeWidth="0.66"
      />
      <path
        d="M18.2206 15.18H19.2106C19.5751 15.18 19.8706 14.8845 19.8706 14.52V12.43C19.8706 10.582 17.6706 9.57001 15.8006 9.57001H11.1806"
        stroke="black"
        strokeWidth="0.66"
      />
      <path
        d="M6.23047 9.56998V8.47131C6.23047 7.98814 6.80752 7.90395 7.09604 7.92226H9.83703C10.4141 7.92226 10.6064 8.28829 10.6305 8.47131V9.23998"
        stroke="black"
        strokeWidth="0.66"
      />
      <path
        d="M11.7305 9.23998V8.47131C11.7305 7.98814 12.3075 7.90395 12.596 7.92226H15.337C15.9141 7.92226 16.1064 8.28829 16.1305 8.47131V9.67998"
        stroke="black"
        strokeWidth="0.66"
      />
      <path
        d="M4.25049 10.01V5.06003C4.25049 4.93852 4.34899 4.84003 4.47049 4.84003H5.90981C6.03131 4.84003 6.12981 4.93852 6.12981 5.06003V5.61003C6.12981 5.73153 6.22831 5.83003 6.34981 5.83003H16.0112C16.1327 5.83003 16.2312 5.73153 16.2312 5.61003V5.06003C16.2312 4.93852 16.3297 4.84003 16.4512 4.84003H17.8905C18.012 4.84003 18.1105 4.93852 18.1105 5.06003V10.01"
        stroke="black"
        strokeWidth="0.66"
      />
    </svg>
  );
}

const MemoListingBeds = React.memo(ListingBeds);
export default MemoListingBeds;
