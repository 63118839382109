export const colors = {
  roveBlue: '#aad4d2',
  roveBlack: '#1d1919',
  roveGreen: '#859485',
  roveDarkGreen: '#5CB3B6',
  roveOffWhite: '#f8f4f1',
  roveOrange: '#f0beaa',
  roveTan: '#c3beb4',
  roveWhite: '#ffffff',
  roveLightBlue: '#70c5c1',
  roveDarkBlack: '#000000',
  roveDarkerTan: '#524E47',
  lightImageBackgroundWithGradientBottom: `
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.5) 77.6%,
      #f8f4f1 100%
    )
  `,
  whiteImageBackgroundWithGradientBottom: `
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.5) 77.6%,
      #ffffff 100%
    )
  `,
  darkImageBackgroundWithGradientBottom: `
    linear-gradient(
      180deg,
      rgba(29, 25, 25, 0.6),
      rgba(29, 25, 25, 0.3),
      #1d1919 100%
    )
  `,
  darkImageBackgroundWithGradientTopBottom: `
    linear-gradient(
      180deg,
      #1D1919 0%,
      rgba(29, 25, 25, 0.55) 51.56%,
      #1D1919 100%
    )
  `,
  whiteImageBackgroundWithGradientTopBottom: `
    linear-gradient(
      180deg,
      #FFFFFF 0%,
      rgba(248, 244, 241, 0.55) 51.56%,
      #FFFFFF 100%
    )
  `,
  lightImageBackgroundWithGradientTopBottom: `
    linear-gradient(
      180deg,
      #F8F4F1 0%,
      rgba(248, 244, 241, 0.55) 51.56%,
      #F8F4F1 100%
    )
  `,
};

export const colorsByTheme = {
  light: {
    textPrimaryColor: colors.roveBlack,
    textSecondaryColor: colors.roveWhite,
    borderPrimaryColor: colors.roveWhite,
    borderSecondaryColor: colors.roveBlack,
    primaryBackgroundColor: colors.roveWhite,
    secondaryBackgroundColor: colors.roveBlack,
    imageBackgroundWithGradientBottom:
      colors.lightImageBackgroundWithGradientBottom,
    imageBackgroundWithGradientTopBottom:
      colors.lightImageBackgroundWithGradientTopBottom,
  },
  white: {
    textPrimaryColor: colors.roveBlack,
    textSecondaryColor: colors.roveWhite,
    borderPrimaryColor: colors.roveWhite,
    borderSecondaryColor: colors.roveBlack,
    primaryBackgroundColor: colors.roveWhite,
    secondaryBackgroundColor: colors.roveBlack,
    imageBackgroundWithGradientBottom:
      colors.whiteImageBackgroundWithGradientBottom,
    imageBackgroundWithGradientTopBottom:
      colors.whiteImageBackgroundWithGradientTopBottom,
  },
  dark: {
    textPrimaryColor: colors.roveWhite,
    textSecondaryColor: colors.roveBlack,
    borderPrimaryColor: colors.roveBlack,
    borderSecondaryColor: colors.roveOffWhite,
    primaryBackgroundColor: colors.roveBlack,
    secondaryBackgroundColor: colors.roveWhite,
    imageBackgroundWithGradientBottom:
      colors.darkImageBackgroundWithGradientBottom,
    imageBackgroundWithGradientTopBottom:
      colors.darkImageBackgroundWithGradientTopBottom,
  },
};
