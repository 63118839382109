import _isInteger from 'lodash/isInteger';
import _isNumber from 'lodash/isNumber';
import { splitByCommas } from '@utils/helpers';

export function toDollars(amount: number, decimals: number | null = null) {
  const numberOfDecimals = _isNumber(decimals)
    ? decimals
    : _isInteger(amount)
    ? 0
    : 2;
  return amount.toLocaleString('us-EN', {
    currency: 'usd',
    style: 'currency',
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals,
  });
}

export const formatPrice = (price?: number) => {
  if (price === 0) return toDollars(0, 2);
  if (!price) return '';
  return splitByCommas(toDollars(price, 2));
};
