import React from 'react';

function ListingBaths(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.43359 13.08V7.45419C6.43359 5.73926 7.5034 5.4571 8.0383 5.53039C8.61806 5.53039 8.97006 5.86018 9.07359 6.02507"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.0332 13.2H20.3532"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.51318 13.2C4.64856 17.2383 6.97624 18.4026 8.12316 18.48H15.9072C19.0208 18.48 19.6864 14.96 19.63 13.2"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.20355 18.48L7.39355 19.44H8.58155L9.55355 18.48"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9434 18.48L16.7534 19.44H15.5654L14.5934 18.48"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7533 7.43999H8.11328C8.11328 7.03999 8.36175 6.23999 9.35563 6.23999C9.82152 6.23999 10.7533 6.47999 10.7533 7.43999Z"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.8335 8.64001V9.36001"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0332 9.35999V9.59999"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.8335 10.56V11.04"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0332 11.04V11.52"
        stroke="black"
        strokeWidth="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoListingBaths = React.memo(ListingBaths);
export default MemoListingBaths;
