import React from 'react';

function ListingGuests(props) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="7.00021"
        cy="3.78049"
        r="3.41463"
        stroke="black"
        strokeWidth="0.731707"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82028 5.82617C2.75242 6.26258 2 7.31172 2 8.53659V14.6342C2 14.9036 2.2184 15.122 2.4878 15.122H11.5122C11.7816 15.122 12 14.9036 12 14.6342V8.5366C12 7.31174 11.2476 6.26262 10.1798 5.8262C10.0392 6.04422 9.87686 6.24686 9.69576 6.43102C10.6049 6.69941 11.2683 7.54052 11.2683 8.5366V14.3903H2.73171V8.53659C2.73171 7.5405 3.39517 6.69937 4.3043 6.431C4.1232 6.24684 3.96084 6.0442 3.82028 5.82617Z"
        fill="black"
      />
    </svg>
  );
}

const MemoListingGuests = React.memo(ListingGuests);
export default MemoListingGuests;
