import { toDollars } from '@utils/money';
import { ListingType } from '@utils/types/listing';
import { FeaturedListingType } from '@utils/types/featuredListing';

export function listingTitleWithoutByRove(title: string) {
  return title
    .replace(' by RoveTravel', '')
    .replace(' by Rove Travel', '')
    .replace(' by Rove', '');
}

export function getListingTitle(listing?: ListingType) {
  if (!listing) return '';
  return (
    listing.cms_listing_title ||
    listingTitleWithoutByRove(listing.listingTitle || listing.title)
  );
}

export function isEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
}

export function splitByCommas(val: string | number) {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getYoutubeThumbnail(youtubeURL?: string) {
  if (!youtubeURL) return undefined;
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = youtubeURL.match(regExp);
  if (match && match[7].length === 11) {
    return `https://img.youtube.com/vi/${match[7]}/0.jpg`;
  } else {
    return undefined;
  }
}

export const stringifyArrayForGQL = (data: string[]) =>
  JSON.stringify(data).replace(/"([^(")"]+)":/g, '$1:');

export function getFormattedListingAddress(address: {
  city: string;
  state: string;
}) {
  return `${address.city ? `${address.city}, ` : ''}${address.state}`;
}

// North America as Default
export const getDefaultMapBounds = () =>
  new window.google.maps.LatLngBounds(
    new google.maps.LatLng(25.81, -111.91),
    new google.maps.LatLng(40.99, -72.17)
  );

export const roundAmountAndConvertToDollars = (
  amount: number,
  decimals: number = 0,
  kAmount = 1000
) => {
  if (amount >= 1000000)
    return `${splitByCommas(toDollars(amount / 1000000, decimals))}M`;
  if (amount >= kAmount)
    return `${splitByCommas(toDollars(amount / 1000, decimals))}K`;
  return toDollars(amount);
};

export const getMinStayPrice = (
  listing: Pick<
    FeaturedListingType,
    'next_available_min_stay_price' | 'average_min_stay_price' | 'min_stay'
  >,
  withFormatting = true
) => {
  const price =
    listing.next_available_min_stay_price ||
    listing.average_min_stay_price ||
    0;

  if (!withFormatting) {
    if (listing.min_stay < 30) {
      return toDollars(price / listing.min_stay, 0);
    }
    return toDollars(price / Math.ceil(listing.min_stay / 30), 0);
  }

  if (listing.min_stay < 30) {
    return `${toDollars(price / listing.min_stay, 0)} / night`;
  }

  const months = Math.ceil(listing.min_stay / 30);
  return `${toDollars(price / months, 0)} / month`;
};
