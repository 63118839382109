import React, { ForwardedRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  BaseComponentProps,
  BaseInputProps,
  InputChangeEvent,
  InputKeyboardEvent,
} from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  inputRef?: ForwardedRef<HTMLInputElement>;
  errorStyles?: string;
  withError?: boolean;
  withRoundCorners?: boolean;
  newAuthField?: boolean;
  reset?: boolean;
  autoComplete?: string;
} & BaseInputProps &
  BaseComponentProps;

const TextInput = (props: Omit<Props, 'value'>) => {
  const {
    onPressEnter,
    onTextChange,
    onBlur,
    label,
    placeholder,
    required,
    touched,
    containerStyle,
    error,
    type,
    name,
    inputRef,
    disabled,
    errorStyles,
    theme,
    withError = true,
    withRoundCorners = false,
    newAuthField = false,
    defaultValue,
    autoComplete,
    reset,
  } = props;

  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (reset) {
      setValue('');
    }
  }, [reset]);

  const onChange = (ev: InputChangeEvent) => {
    const { value } = ev.target;
    setValue(value);
    onTextChange && onTextChange(value);
  };

  const onKeyDown = (ev: InputKeyboardEvent) => {
    if (ev.key === 'Enter') {
      onPressEnter && onPressEnter(value);
    }
  };

  return (
    <div
      className={clsx([
        styles.container,
        containerStyle,
        styles[`variant${theme}`],
        withRoundCorners && styles.roundCorners,
        newAuthField && styles.newAuthField,
      ])}
    >
      {label && <span>{label}</span>}

      <input
        ref={inputRef}
        name={name}
        type={type}
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autoComplete}
      />

      {withError && (
        <div className={clsx(styles.error, errorStyles)}>
          {!!error && touched && <li>{error}</li>}
        </div>
      )}
    </div>
  );
};

TextInput.defaultProps = {
  required: false,
  touched: false,
  disabled: false,
  error: '',
  errorStyles: '',
};

export default TextInput;
